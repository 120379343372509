.new-transactions-container {
  display: flex;
  width: 100%;
  border: 1px solid $newTransactionsContainerBorder;
  border-radius: 6px;
  padding: 0 1rem;
  padding-bottom: 20px;
  margin-top: 20px;
  flex-direction: column;
  box-sizing: border-box;

  .title {
    width: 100%;
    letter-spacing: 0px;
    color: $dashboardBackground;
    h2 {
      margin: 10px 0 20px 0;
      font-size: 1.25rem;
      margin-right: 31px;
    }
  }

  .new-transaction-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .app-brand-container {
      display: flex;
      align-items: center;
      flex-basis: 35%;
      .new-transactions-text {
        color: $newTransactionsTextColor;
        font-size: 1.2em;
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-right: 1rem;
      }
      .new-transactions-switch-button {
        float: right;
        border-radius: 30px;
        font-size: x-small;
        color: $switchButtonColor;
        background-color: transparent;
        border: 1px solid $switchButtonBorderColor;
        box-shadow: none;
        padding: 2px 10px;
        cursor: pointer;
        margin-left: 4px;
        &:focus {
          border: $lightFocus;
        }
      }
    }
    .refresh-button-container {
      width: 10rem;
      .custom-single-buttons-container {
        width: 10rem;
        .outline-primary {
          .btn {
            padding: 5px;
            font-size: 1rem;
            background-color: $transparentBackground;
            &:hover {
              background-color: $rightMenuSelectedBackgroundColor;
              border-color: $rightMenuSelectedBackgroundColor;
            }
            &:active {
              background-color: $activeButtonColor;
            }
            &:focus {
              background-color: $transparentBackground;
              color: $activeButtonColor;
              svg {
                fill: $activeButtonColor;
              }
            }
          }
        }
      }
    }
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20.563rem;
    .custom-single-buttons-container {
      width: 18.313rem;
      .btn {
        padding: 5px;
        font-size: 1rem;
        &:disabled {
          opacity: 1;
          background-color: $outlineSecondaryDisabledColor;
          border-color: $outlineSecondaryDisabledColor;
          color: $buttonContainerDisabledColor;
        }
      }
    }
    .ta-new-transaction-loader-container, .ta-borading-loader-container {
      position: inherit;
      width: 31px;
      display: flex;
      align-items: center;
      justify-content: start;
      .loader {
        position: absolute;
      }
    }
  }

  .start-new-review-button {
    width: 100%;
    display: block;
    padding: 5px;

    &:hover {
      cursor: unset;
    }
  }

  .action-button-container {
    display: flex;
    flex-basis: 35%;
    justify-content: flex-end;
    .new-transactions-search-button {
      float: right;
      border-radius: 30px;
      font-size: x-small;
      color: $switchButtonColor;
      background-color: transparent;
      border: 1px solid $switchButtonBorderColor;
      box-shadow: none;
      padding: 2px 10px;
      cursor: pointer;
      margin-left: 4px;

      &:focus {
        border: $lightFocus;
      }
    }

    .new-transactions-search-button {
      margin-right: 5px;
    }

    .new-transactions-icon {
      display: block;
      margin: auto;
      width: 14px;
      height: 14px;
    }
  }
}

.no-transaction-found {
  width: 100%;
  border: 1px solid $modalFailColor;
  color: $modalFailColor;
  text-align: start;
  border-radius: $modalBorderRadius;
  @include flex-align-center;
  padding: 6px;

  .bold-text {
    font-weight: bold;
  }

  p {
    margin: 5px;
  }
}

.no-transaction-found-text {
  color: $shieldColor;
  font-size: 0.9em;
}


