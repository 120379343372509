.modal-header, .modal-body, .modal-footer {
  width: 100%;
  height: auto;
}

.modal-header {
  .modal-title {
    width: 100%;
    text-align: center;
  }
  .close {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.face-image-wrapper-container {
  width: 50%;
  height: 25rem;
  margin: auto;
}

.a4-image-wrapper-container {
  width: 25%;
  height: 32rem;
}

.address-image-wrapper-container {
  width: 30%;
  height: 40rem;
  margin: auto;
}
