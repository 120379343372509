.driving-license-form {
  width: 100%;
  border-radius: 0.5rem;

  p {
    text-align: start;
    font-size: 0.8125rem;
    width: 100%;
    margin: 1.25rem 0 0.625rem;
  }
  .driving-license-form-heading {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.3rem;
    width: 73%;
    font-size: 12px;
  }

  .driving-license-form-container {
    width: 100%;
    height: 16rem;
    overflow-y: auto;

    .driving-license-form-row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.625rem;

      span {
        font-size: 0.875rem;
        text-align: start;
        width: 22%;
        max-width: 22%;
      }

      .select-container-driving-fields {
        width: 22%;
        max-width: 22%;
        height: auto;

        .select-container {
          width: 100%;

          .select-item {
            [class$="-container"] {
              [class$="-control"] {
                width: 100%;

                .dl-item__input {
                  padding: 0;
                }

                .dl-item__indicators {
                  height: auto;
                }
              }
            }
          }
        }
      }

      .date-picker-main {
        width: 22%;
        max-width: 22%;
        height: auto;
        border: 1px solid $modalBorderColor;
        outline: none;
        .react-datepicker-wrapper {
          width: 100%;
          .react-datepicker__input-container>input {
            width: 100%;
            font-size: 0.875rem; // 14px
            border: none;
            border-radius: 0;
            outline: none;
            color: #000;
          }
        }
      }

      .restrictions {
        width: 22%;
        max-width: 22%;
        padding: 0.5rem;
        font-size: 0.875rem;
        border: 1px solid;
        text-align: start;
        outline: none;
        border: 1px solid $modalBorderColor;
      }

      .remove-row {
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 1.125rem;

        svg {
          fill: red;
        }
      }
    }

    .add-row {
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 1.125rem;
      margin-left: auto;
      margin-top: 10px;
      fill: #0063b0;
    }
  }
}