.data-verification-content {
  .data-verification-accordion-content {
    width: 100%;
    height: auto;
    height: 86rem;
    display: flex;
    align-items: center;
    flex-direction: column;

    .data-verification-form {
      width: 100%;
      display: flex;
      justify-content: space-between;
      h2 {
        width: 50%;
        font-size: 0.875rem;
        text-align: start;
        margin: 0 1rem;
      }

      .required-fields, h2, .driving-license-form {
        width: 50%;
        height: auto;
        align-items: center;

        .input-container {
          .user-input {
            &.pass::placeholder {
              color: $resultColor;
            }
            &.fail::placeholder {
              color: $resultRejectColor;
            }
          }
        }
      }
    }

    .line {
      width: 96%;
      border-top: 0.0625rem solid #D6D6D6;
    }

    .driving-license-form {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      h2 {
        grid-column: span 4;
        font-size: 0.875rem;
        text-align: start;
        margin: 1rem;
      }
      .input-container {
        margin: 0.5rem 1rem;
      }
    }
  }
}