.face-verification-section {
  height: 41rem;
  width: 100%;
  padding: 1rem;
  padding-top: 0;
  .face-verification-status {
    font-size: 0.75rem;
    font-weight: 600;
    text-align: left;
    .fail {
      padding-left: 0.5rem;
      color: $resultRejectColor;
    }
    .pass {
      padding-left: 0.5rem;
      color: $resultColor;
    }
  }
  .face-verification-images {
    display: flex;
    justify-content: space-between;
    width: 100%;
    .wrapper-transform-component{
      width: 48%;
      height: 20rem;
    }
  }
}