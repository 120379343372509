.video-player {
  height: 100%;
  width: 100%;
  margin-top: 0px;
  .video-cont {
    height: 100%;
    width: 100%;
    background: $videoPlayerNoRenderingBackgroundColor;
    border-radius: 6px;
    video {
      height: 100%;
      width: 100%;
      border-radius: 6px;
    }
    video:fullscreen {
      object-fit: contain;
    }
    video:-webkit-full-screen {
      object-fit: contain;
    }
  }
  .figcaption {
    position: absolute;
    bottom: 0;
    height: 36px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background: $videoPlayerFooterBackgroundColor;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    .pause-play-fullscreen-section {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $videoPlayerButtonBackgroundColor;
      height: 32px;
      width: 32px;
      margin: 2px 3px;
      border-radius: 4px;
      .fill-white {
        fill: $videoIconFillColor;
      }
      .fill-white:hover {
        cursor: pointer;
      }
      .fullscreen-enable {
        fill: $videoIconFillColor;
      }
      .fullscreen-enable:hover {
        cursor: pointer;
      }
      .fullscreen-disable {
        fill: $videoPlayerFullscreenIconColor;
      }
      .fill-fullscreen-white:hover {
        cursor: pointer;
      }
      .speed-up-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem;
        width: 2rem;
        height: 2rem;
        color: $videoIconFillColor;
        &:hover {
          cursor: pointer;
        }
      }
    }
    .progress-bar {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: transparent;
      .inputR {
        position: absolute;
        -webkit-appearance: none;
        appearance: none;
        width: 100%;
        height: 6px;
        outline: none;
        margin: 0 auto;
        cursor: pointer;
        border-radius: 5px;
        background: $videoPlayerSecondsCoverColor;
      }
      .inputR::-webkit-slider-thumb {
        appearance: none;
        -webkit-appearance: none;
        background: $videoPlayerCurrentTimeColor;
        border-radius: 50%;
        border: 0px solid $videoInputRangeColor;
        box-shadow: 0px 2px 4px $videoInputRangeBoxShadowColor;
        width: 16px;
        height: 16px;
      }
      .inputR::-moz-range-progress {
        background-color: $videoPlayerCurrentTimeColor;
        height: 6px;
      }
      input[type=range]::-webkit-slider-runnable-track {
        background: transparent;
      }
      .inputR::-moz-range-thumb {
        border: 0px solid $videoInputRangeColor;
        width: 16px;
        height: 16px;
        border-radius: 45px;
        background: $videoPlayerCurrentTimeColor;
        box-shadow: 0px 2px 4px $videoInputRangeBoxShadowColor;
        cursor: pointer;
      }
      .inputR::-moz-focus-inner {
        border: 0;
      }
      .inputR::-moz-focus-outer {
        border: 0;
      }
      .back-cover {
        width: 100%;
        height: 10px;
        background: $videoPlayerSecondsBackgroundColor;
        border-radius: 5px;
      }
    }
  }
}

.video-not-found-section {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-color: $secondaryButtonTextColor;
  border-radius: 6px;
  font-size: 1.25rem;
  font-weight: 600;
  border: 1px solid $secondaryButtonTextColor;
  height: inherit;
}