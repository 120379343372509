.summery-review-content {
  margin: 0 .3rem;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  .transaction-summary-section {
    border-radius: 0.3125rem;
    background: $backgroundWhite;
    width: 100%;
    margin-top: 1rem;
  }

  .dv-summary-section {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 1rem;

    .data-verification-content, .document-validation-content {
      width: 50%;
      height: auto;
    }
  }
  .selfie-and-doc-scan-summary-section {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    gap: 1rem;

    .selfie-validation-section, .doc-scan-summary-section {
      width: 50%;
    }

    .selfie-validation-section {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .selfie-video-content{
        width: 40%;
      }
      .face-verification-content {
        width: 60%;
      }
    }
  }
  .address-review-section {
    display: flex;
    width: 100%;
    height: auto;
    margin: 1rem 0;
  }
}

.accordion-container  {
  .accordion {
    .card {
      .card-header {
        width: 100%;
        background: $backgroundWhite;
        border-bottom: 1px solid transparent;
        border-radius: 0.3125rem;
        .accordion-title {
          .left-info {
            width: 100%;
            display: flex;
            align-items: center;
          }
          .left-info .accordion-title-info {
            width: 100%;
            order: -1; /* Move the text before the SVG */
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 1.25rem;
            text-align: left;
            font-weight: 600;
            line-height: 1.5rem;
            margin: 0;
          }
          svg {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 0.625rem;
          }
        }
      }
      .card-body {
        padding: 0.5rem;
      }
    }
  }

  h1 {
    font-size: 1.25rem;
    text-align: left;
    font-weight: 600;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }
}

.summery-review-content::-webkit-scrollbar {
  width: 0.25rem;
}