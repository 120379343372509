.modal-wrapper {
    background: $modalBackground;
    border: 1px solid $modalBorderColor;
    box-shadow: $modalShadow;
    width: 80%;
    min-width: 550px;
    position: absolute;
    top: 1rem;
    border-radius: $modalBorderRadius;
    z-index: 11;
    left: 10%;

    &:focus {
      outline: none;
    }
}

.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: center;
    border-bottom: 0;
    &:focus {
      outline: none;
    }
}

.dashboard-modal-title {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  align-items: center;
  p {
    margin: 0 5px;
  }
  .search-input {
    padding: 3px 10px;
    border: 1px solid $searchInputBorderColor;
    align-items: center;
    border-radius: 15px;
    display: flex;
    margin-left: 25px;
    font-weight: 100;
    .trcode-input {
      border: 0;
      outline: none;
      width: 20rem;
      padding: 0;
      font-size: 15px;
    }
    svg {
      height: 15px;
      width: 15px;
    }
  }
}

.switch-dataset-modal-title {
  width: 100%;
  justify-content: center;
  margin-left: 20px;
  &:focus {
    outline: none;
  }
}

.modal-header h3 {
    float: left;
    margin: 0;
    padding: 0;
}

.modal-body {
    padding-bottom: 1rem;
    padding: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-bottom: .5rem;

    .search-failed {
        width: 100%;
        border: 1px solid $modalFailColor;
        color: $modalFailColor;
        text-align: start;
        border-radius: $modalBorderRadius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .25px;
        margin-top: .5rem;
        .bold-text {
            font-weight: bold;
        }

        p {
            word-break: break-all;
            margin: 5px;
        }

        .try-again-button {
            margin: 5px;
            font-weight: bold;
        }
    }

    .search-success {
      width: 100%;
      display: flex;
      box-shadow: 0px 3px 6px $modalShadow;
      background: $modalFailColor;
      color: $modalSearchTextColor;
      box-shadow: 0px 3px 6px $modalShadow;
      padding: 10px;
      border: 1px solid;
      border-radius: $modalBorderRadius;
      margin-top: .5rem;
      div {
        display: flex;
        float: left;
      }
      .brand {
        width: 20%;
      }
      .tid {
        width: 75%;
      }
      .review {
        cursor: pointer;
        flex: 1;
        display: flex;
        text-align: end;
        align-items: center;
        .text {
          flex: 1;
        }
        .loader {
          @include icon-size(.8rem, .8rem);
          margin-left: 1rem;
          border-top: 0.2em solid white;
        }
      }
      .tid,
      .review {
        font-weight: 600;
      }
      .search-success-review-button:hover {
        cursor: default;
      }
    }
}

.switch-dataset-modal {
  padding: 0;
  .ta-review-table  {
    border: 0;
    thead {
      tr {
        &:nth-child(1) {
          font-size: .88rem;
          th {
            font-weight: normal;
            &:nth-child(5) {
              width: 18%;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          .custom-single-buttons-container{
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          border-bottom: 1px solid $tableBorderColor;
          border-top: 0;
        }
        &.selected {
          background-color: $tableSelectedBorderColor;
          color: $modalBackground;
          td {
            border: 1px solid $tableSelectedBorderColor;
          }
        }
      }
    }
  }
}

.modal-footer {
    height: 35px;
    padding: 15px;
}

.close-modal-button {
  border: 2px solid transparent;
  padding: 2px 2px;
  &:focus {
    border: 2px solid $defaultFocus;
  }
  svg {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }
}

.close-modal-btn:hover {
    color: $closeModalBtnColor;
}

.btn-cancel, .btn-continue {
    background: coral;
    border: none;
    color: $btnCancelContinueColor;
    cursor: pointer;
    font-weight: bold;
    outline: none;
    padding: 10px;
}

.btn-cancel {
    background-color: $modalCancelButton;
    float: left;
}

.btn-continue {
    background-color: $modalContinueButton;
    float: right;
}

.back-drop {
    background-color: rgba(48, 49, 48, 0.42);
    height: 100%;
    position: fixed;
    transition: all 1.3s;
    width: 100%;
}

.open-modal-btn {
    margin: 15px;
    padding: 10px;
    font-weight: bold;
}
