.review-content {
  height: calc(100vh - 7.65rem);
  overflow-y: auto;
  padding: 0 .5rem;
}
.manual-check-reasons-section {
  margin: 0 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.25rem;
  p {
    font-size: 11px;
    margin: 0;
    span {
      padding-left: .25rem;
      color: $codeColor;
      font-weight: bold;
    }
  }
  .need-some-help {
    color: $helpLinkColor;
  }
  .need-some-help:hover {
    cursor: pointer;
  }
}