.footer-container {
  width: 100%;
  background-color: $footerBackgroundColor;
  border-radius: 0.625rem 0.625rem 0 0;
  text-align: start;
  margin: auto;
  padding: 0.938rem 2.5rem;
  color: $footerTextColor;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  height: 13rem;

  .manual-check, .summary {
    width: 48%;
    border-radius: 0.625rem;
  }

  .manual-check h6, .summary h6 {
    font-weight: bold;
  }

  .check-item {
    display: flex;
    align-items: center;
    font-size: .75rem;
    .check-mark {
      font-size: .75rem;
      margin-right: 0.625rem;
    }
    .cross {
      color: $crossMarckColor;
    }
    .tick {
      color: $tickMarkColor;
    }
  }

  .result {
    font-weight: bold;
    font-size: .75rem;
    margin-left: 0.625rem;
  }

  .post-processing {
    margin-top: 1.25rem;

    .line {
      width: 80%;
      border-top: 0.156rem solid $footerItemsBorderColor;
      margin-bottom: 0.625rem;
    }

    div {
      display: flex;
      align-items: center;

      label {
        margin-left: 0.625rem;
        margin-bottom: 0;
        font-size: .75rem;
      }
    }

    input[type="checkbox"] {
      width: 1rem;
      height: 1rem;
    }
    input[type="checkbox"]:checked {
      accent-color: $checkboxColor;
    }
  }

  .summary textarea {
    width: 100%;
    height: 6.25rem;
    background-color: $footerBackgroundColor;
    color: $footerTextColor;
    border: 0.063rem solid $footerItemsBorderColor;
    border-radius: 0.313rem;
    padding: 0.625rem;
    margin-bottom: 1.25rem;
  }

  .action-buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;

    button {
      font-size: 1rem;
      cursor: pointer;
      width: 48%;
      background-color: $footerBackgroundColor;
      border: 0.063rem solid $footerItemsBorderColor;
      border-radius: 1.25rem;
      color: $footerTextColor;
    }

    button:active {
      outline: none;
      background-color: $footerItemsBorderColor;
      border: 0.063rem solid $footerBackgroundColor;
      color: $footerBackgroundColor;
    }
  }
}