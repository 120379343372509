.identity-review-content {
  .document-validation-content {
    border-radius: 0.3125rem;
    background: white;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
  }
  .lfv-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;
    .liveness-content {
      border-radius: 0.3125rem;
      background: white;
      width: 50%;
      margin-right: .5rem;
    }
    .face-verification-content {
      border-radius: 0.3125rem;
      background: white;
      width: 50%;
      margin-left: .5rem;
    }
  }
}