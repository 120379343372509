
.self-declaration-data-content {
  width: 50%;
  .self-declaration-title {
    font-weight: 400;
    font-size: 1.125rem;
    text-align: left;
    margin: 0 1rem;
  }
}

.extracted-address-content {
  width: 50%;
  .extracted-address-title {
    font-weight: 400;
    font-size: 1.125rem;
    text-align: left;
    margin: 0 1rem;
  }
}

.input-container {
  position: relative;
  margin: 1rem;
  input {
    padding: .75rem .7rem;
    line-height: 1.5;
    width: 100%;
    height: 3rem;
    border: 1px solid #D6D6D6;
    border-radius: 0.35rem;
    font-size: 1rem;
    color: hsl(0, 0%, 50%);
    box-sizing: border-box;
    background: #F4F4F4;
    border-color: #D6D6D6;
  }
  .equals-style {
    color: $resultFailedBackground;
  }
  .placeholder {
    position: absolute;
    left: 9px;
    top: -.5rem;
    font-size: 0.85rem;
    padding: 0 0.2rem;
    color: hsl(0, 0%, 50%);
    background: #ffffff;
  }
}
