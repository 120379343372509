.ta-boarding {
  @include flex-direction;
  padding-top: 50px;
  align-items: center;
  flex: 1 1;
  overflow-y: auto;
  border-radius: 0.3125rem;
  background: white;
  margin: 0 0.5rem 0.5rem 0.5rem;
  .ta-boarding-info {
    text-align: start;
    font-size: 1.12rem;
    flex: 1 0 auto;
  }
  .ta-boarding-title {
    font-size: 1.87rem;
    margin-bottom: 20px;
  }

  .ta-failed-text {
    margin: 2rem auto .5rem;
  }

  .ta-failed-reason {
    color: $resultFailedBackground;
    font-size: 1.25rem;
    max-height: 12rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 0.25rem;
      height: 100%;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $slideColor;
      border-radius: 0.5rem;
    }
    &::-webkit-scrollbar-track {
      background-color: $backgroundColor;
      border-radius: 0.563rem;
    }
  }

  ul {
    list-style-type: square;
    margin: .5rem 0 2rem 1rem;
    padding: 0;
    padding-inline-start: 5px;

    li {
      white-space: pre-wrap;
      padding: 0.3rem 0;
      padding-right: 0.5rem;

      &::before {
        height: 15px;
      }
    }
  }

  .ta-swiss {
    font-size: 1.3rem;
  }

  .ta-swiss,
  .ta-check-type {
    font-weight: bold;
  }

  .ta-check-type {
    margin-bottom: 40px;
  }
  .ta-boarding-buttons-container {
    display: flex;
    align-items: center;
    width: 100%;
    .start-transaction-button-ta-boarding {
      height: 2rem;
      margin: 1.3rem 8.125rem 1.3rem 1.3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .custom-single-buttons-container {
        width: max-content;
        margin-bottom: 0;
        .outline-secondary {
          display: flex;
          align-items: center;
          justify-content: center;
          .btn,
          .btn-outline-secondary {
            width: max-content;
            display: flex;
            margin-top: 0;
            height: 2rem;
            font-size: 1rem;
            margin-top: 0;
            &:disabled {
              opacity: 1;
              background-color: $outlineSecondaryDisabledColor;
              border-color: $outlineSecondaryDisabledColor;
              color: $outlineSecondaryDisabledMainColor;
            }
          }
        }
      }
      .ta-borading-loader-container {
        position: inherit;
        width: 31px;
        display: flex;
        align-items: center;
        justify-content: start;
        .loader {
          position: absolute;
        }
      }
    }
    .ta-boarding-back-button-container {
      height: 2rem;
    }
  }
}
