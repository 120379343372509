.modal-backdrop {
  opacity: unset;
}
.show, .fade {
  opacity: unset;
  pointer-events: inherit;
}
.big-modal, .white-big-modal {
  .modal-content {
    background-color: $bigModalBackgroundColor;
    color: $bigModalTextColor;
    border-radius: 0.625rem;
    .modal-header {
      justify-content: center;
      .modal-title {
        text-align: center;
        margin-top: 1.25rem;
        font-weight: normal;
        font-size: 1.25rem;
      }
      button {
        position: absolute;
        top: .5rem;
        right: .5rem;
      }
    }
    .modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;

      textarea {
        width: 30rem;
        resize: none;
        background-color: transparent;
        border-radius: 0.625rem;
        padding-left: 0.625rem;
        border: 1px solid $backgroundWhite;
        color: $backgroundWhite;
        height: 10.938rem;
        min-width: 14.063rem;
        margin-top: 1.563rem;
        &:focus {
          outline: none;
          border: 1px solid $inputFocus;
        }
        &::placeholder {
          color: $bigModalTextColor;
        }
      }
      .post-processing-section {
        text-align: left;
        margin-bottom: 1rem;
        display: flex;
        flex-direction: row;
        .post-processing-title {
          margin-left: 0.25rem;
        }
        .post-processing-checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          input[type="checkbox"] {
            width: 1rem;
            height: 1rem;
          }
          input[type="checkbox"]:checked {
            accent-color: $tableHoverBackground;
          }
        }
      }
      .modal-confirmation-note {
        color: $modalMessageTextColor;
        font-size: 0.938rem;
      }
      .assessment-text-style {
        color: $BigModalTextColorWhite;
        font-size: 0.938rem;
        padding-top: 0.625rem;
      }
      .dicide-text-style {
        font-size: 1.25rem;
        color: $BigModalTextColorWhite;
        font-weight: 600;
      }
      p {
        font-size: 1.3rem;
        margin-bottom: 0;
      }
      .message {
        max-width: 36.25rem;
        font-size: 1.25rem;
        margin: 0.625rem auto;
      }
      .message-first {
        color: $bigModalTextColor;
      }
      .message-second {
        color: $modalMessageTextColor;
      }
    }
    .withButtonBody {
      margin-bottom: 1.25rem;
      align-items: center;
      .loader {
        margin-left: 0.625rem;
      }
    }
    .modal-footer {
      height: auto;
      border: none;
      padding: 1.25rem 0;
      .custom-single-buttons-container {
        margin: 0 0.625rem;
      }
    }
    .modal-footer-separate {
      height: auto;
      border: none;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      .custom-single-buttons-container {
        .big-outline {
          .btn-big-outline {
            font-size: 1rem;
          }
        }
        .big-inline {
          .btn {
            color: $buttonBackgroundColor;
          }
        }
      }
    }
    .modal-footer-join {
      display: flex;
      justify-content: center;
      button {
        margin: 0;
      }
    }
    .left {
      justify-content: left;
    }
  }
}
.big-modal {
  .loader {
    border-top: 0.2em solid $backgroundColor;
  }
}
.white-big-modal {
  .modal-dialog {
    max-width: 43.125rem;
    min-width: 25rem;
  }
  .modal-content {
    background-color: $containerBackgroundColor;
    .modal-header {
      .modal-title {
        color: $bigModalTitleColor;
        width: 60%;
        .sub-title {
          font-size: 1.2rem;
          margin: 0.625rem auto;
          max-width: 35rem;
          text-align: center;
          min-width: 25rem;
        }
      }
    }
    .modal-body {
      div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .input-container {
          position: relative;
          margin-bottom: 1.2rem;

          .big-modal-input {
            border: 1px solid $inputBorderColor;
            width: 16.875rem;
            padding: 0.625rem;
            color: $bigModalInput;
            border-radius: 1.563rem;

            &::placeholder {
              color: $inputPlaceholderColor;
              font-size: 0.938rem;
              text-align: center;
            }
          }
          .big-modal-input:focus {
            border-color: $inputBorderColorActive;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset,
              0 0 1px $inputBorderColorActive;
            outline: 0 none;
            &::placeholder {
              color: transparent;
            }
          }
          .big-modal-input:focus ~ p {
            display: block;
            font-size: 0.9em;
          }
          svg {
            @include icon-size(auto, 1.3rem);
            position: absolute;
            right: 0.625rem;
            top: .9rem;
          }
          p.placeholder {
            position: absolute;
            color: $inputBorderColorActive;
            top: -0.625rem;
            background-color: $backgroundWhite;
            padding: 0px 5px;
            left: unset;
          }

          p.display-none {
            display: none;
          }
        }

      .big-modal-input:focus ~ p {
        display: block;
        font-size: 0.9em;
      }

      svg {
        @include icon-size(auto, 1.3rem);
        position: absolute;
        right: 0.625rem;
        top: .9rem;
      }

      p.placeholder {
        display: none;
        position: absolute;
        left: 9px;
        top: -.5rem;
        padding: 0 0.2rem;
        color: $inputBorderColorActive;
        background: $containerBackgroundColor;
      }

      p.display-none {
        display: none;
      }
        textarea {
          border: 1px solid $contentTextColor;
          color: $contentTextColor;
          &:focus {
            border: 1px solid $inputFocus;
            outline: none;
          }
          &::placeholder {
            color: $contentTextColor;
          }
        }
        .big-modal-sub {
          color: $contentTextColor;
          width: 31.25rem;
          font-size: .93rem;
        }
      }
    }
  }
}