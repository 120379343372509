.face-verification-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem 2.5rem;
  .face-verification-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 24.38px;
    text-align: left;
  }

  .face-verification-main {
    display: flex;
    .face-verification-section {
      display: flex;
      flex-direction: column;
      height: 40rem;
      width: 50%;
      position: relative;

      .face-avatar-section {
        height: 20rem;
      }
      .face-image-section {
        padding-bottom: 0.5rem;
        height: 20rem;
      }
    }
    .review-section {
      width: 50%;
      padding-left: 1rem;
      .face-verification-status {
        font-size: 1.25rem;
        font-weight: 600;
        text-align: left;
        .fail {
          color: #e9425e;
        }
        .pass {
          color: #04822A;
        }
        .notPerformed {
          color: #FFA500;
        }
      }
      .line {
        border-top: 0.0625rem solid #D6D6D6;
        margin-bottom: 0.625rem;
      }
      .review {
        display: flex;
        flex-direction: column;
        .review-text {
          text-align: left;
          font-size: 18px;
          font-weight: 500;
          padding: 1rem 0;
          text-align: left;
        }
        .checkbox-section {
          display: flex;
          .review-description {
            color: #313030;
            font-size: 13px;
            font-weight: 400;
            line-height: 15.85px;
            text-align: left;
            margin-left: .5rem;
            svg {
              width: 1.125rem;
              height: 1.125rem;
            }
            svg:hover {
              cursor: pointer;
            }
          }
          input[type="checkbox"] {
            height: 1rem;
          }
          input[type="checkbox"]:checked {
            accent-color: $checkboxColor;
          }
        }
      }
      .review-buttons {
        display: flex;
        flex-direction: column;
        margin-top: 3rem;
        .description {
          color: #313030;
          font-size: 18px;
          font-weight: 400;
          text-align: left;
        }
        .answer-section {
          display: flex;
          margin: 2rem 0;
          .radio-button {
            height: 1.75rem;
          }
          .radio-button input[type="radio"] {
            width: 1.75rem;
            height: 1.75rem;
            outline: none;
            accent-color: #e9425e;
            cursor: pointer;
          }
        }
      }
    }
  }
}
