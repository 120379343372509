.custom-comparison-input-container {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.2rem 0;

  .custom-input-field-key {
    text-align: start;
    font-size: .9rem;
    width: 24%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.3rem;
  }

  .custom-input-container {
    display: flex;
    justify-content: space-around;
    width: 38%;

    .date-picker-main {
      width: 80%;
      border: 1px solid $modalBorderColor;
      outline: none;
      .react-datepicker-wrapper {
        width: 100%;
        .react-datepicker__input-container>input {
          width: 100%;
          padding: 0.5rem;
          font-size: 0.875rem; // 14px
          border: none;
          border-radius: 0;
          outline: none;

          color: $matchingTextColor;

          &.mismatch {
            color: $notMatchingTextColor;
          }

          &.edited {
            background-color: $copiedInputColor;
          }

          &.not-editable {
            color: #000;
            background-color: $tableSearchInputBackground;
          }

          &:disabled {
            background-color: $tableSearchInputBackground;
          }
        }
      }
    }

    .custom-input {
      width: 80%;
      padding: 0.5rem;
      font-size: 0.875rem; // 14px
      border: 1px solid $modalBorderColor;
      color: $matchingTextColor;
      outline: none;

      &.mismatch {
        color: $notMatchingTextColor;
      }

      &.edited {
        background-color: $copiedInputColor;
      }

      &.error {
        border-color: $notMatchingTextColor;
      }

      &.not-editable {
        color: #000;
        background-color: $tableSearchInputBackground;
      }

      &:disabled {
        background-color: $tableSearchInputBackground;
      }
    }

    .select-container {
      width: 80%;
    }

    button {
      width: 20%;
      font-size: 1rem;
      color: $copyButtonColor;
      background: transparent;
      border: none;
      cursor: pointer;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.2);
      }

      &:focus {
        outline: none;
      }
    }

    .copy-allowed {
      color: #00a1ed;
    }
  }
}