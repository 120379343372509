.dashboard {
    height: calc(100vh - 38px);
    background: $backgroundColor 0% 0% no-repeat padding-box;

    .dashboard-container {
      height: calc(100vh - 38px);
      width: 80%;
      margin: auto;
      flex-direction: column;
      display: flex;
      align-items: center;
      max-width: 80%;

        .overlap-area {
          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          background: $dashboardOverlapAreaBackground;
          opacity: 0.3;
          z-index: 10;
          display: block;
          &.overlap_disable {
            display: none;
          }
        }

        .dashboard-history-loader {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 5rem;

          .loader {
            @include icon-size(2.5rem, 2.5rem);
          }
        }
        .transactions-table {
          width: 100%;
          border-radius: 10px;
          border: 1px solid $historyScrollBarColor;
          box-sizing: border-box;
          margin-top: 20px;
          padding-bottom: 5px;
          .transaction-table-switch {
            .show-active-btn {
              min-width: 120px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
            }
            .show-closed-btn {
              min-width: 120px;
              border-top-left-radius: 0;
              border-bottom-left-radius: 0;
            }
            .btn {
              transition: 500ms;
              &:focus {
                box-shadow: none;
              }
              &:hover {
                opacity: 0.7;
              }
            }
            .btn-dark {
              &:focus {
                box-shadow: none;
              }
            }
          }
          .transactions-table-pagination {
            display: flex;
            justify-content: end;
            .export-button-container {
              margin-right: 1rem;
              max-height: 2rem;
              .custom-single-buttons-container {
                margin: 0;
                .outline-primary {
                  height: 100%;
                  .btn {
                    margin-top: 0;
                    padding: .5rem 2rem;
                    font-size: .88rem;
                    background-color: $transparentBackground;
                    svg {
                      fill: $buttonBackgroundColor;
                      transform: rotate(0deg);
                      height: 1.25rem;
                      width: 1.25rem;
                    }
                    &:hover {
                      background-color: $rightMenuSelectedBackgroundColor;
                      border-color: $rightMenuSelectedBackgroundColor;
                    }
                    &:active {
                      background-color: $activeButtonColor;
                    }
                    &:focus {
                      background-color: $transparentBackground;
                      color: $activeButtonColor;
                      svg {
                        fill: $activeButtonColor;
                      }
                    }
                  }
                }
              }
            }
            .pagination {
              max-width: 25rem;
              max-height: 2rem;
              display: flex;
              justify-content: space-between;
              border: 1px solid $pgnItemColor;
              .displayText {
                font-size: .88rem;
              }
              .btnCollection {
                display: flex;
                flex-direction: row;
                .bttArrow {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  .page-link {
                    font-size: 1.5rem;
                    padding: 0 1rem;
                    font-weight: 500;
                  }
                }
                .active {
                  .page-link {
                    color: $paginationArrowColor;
                  }
                }
              }
              .page-item {
                .page-link{
                  border: none;
                  background-color: transparent;
                }
                .page-link:hover, .page-link:focus {
                  box-shadow: none;
                  text-decoration: none;
                }
              }
            }
          }
          table {
            border-top: 0;
            border-bottom: 0;
            thead {
              border-top: 0;
              tr {
                &:first-child {
                  border-top: 0;
                }
              }
            }
            tfoot {
              border-bottom: 0;
              tr {
                border-bottom: 0;
              }
            }
          }
          thead, tr {
              display: table;
              width: 100%;
              table-layout: fixed;
          }
          tbody {
            display: block;
            height: calc(100vh - 475px);
            overflow-y: auto;
            width: 100%;
          }
        }
    }
}
