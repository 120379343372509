.document-validation {
  height: 86rem;
  .clarifier-selection-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .first-page-content,
  .second-page-content,
  .signature-content {
    width: 50%;
    display: inline-block;
    h2 {
      font-size: 1.125rem;
      font-weight: 500;
      text-align: start;
      margin: 0 1rem;
    }
    .image-wrapper-container,
    .document-validation-image-comparison {
      margin: 1rem;
      height: 20rem;
    }

    .video-player-section {
      margin: 1rem;
      height: 20rem;
      position: relative;
      .video-player {
        .figcaption {
          .progress-bar {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            position: relative;
            background-color: transparent;
          }
        }
      }
    }
  }

  .signature-content {
    width: 50%;
    display: grid;
    .image-wrapper-container {
      height: 12rem;
    }
  }
}
