.select-container{
  display: inline-block;
  width: 100%;
  height: 100%;
  .select-item {
    width: 100%;
    height: 100%;
    :hover {
      cursor: pointer;
    }
    .select-element_valid {
      width: 100%;
      height: 100%;
      [class$="-control"] {
        width: 100%;
        box-shadow: none;
        &:focus {
          border: 1px solid $selectElementBorderColor !important;
        }
        :first-child {
          display: flex;
          align-items: baseline;
        }
        :last-child {
          display: flex;
          align-items: baseline;
          height: 100%;
          :last-child {
            display: flex;
            align-items: baseline;
            height: 100%;
            width: max-content;
            display: flex;
            align-items: center;
          }
        }
      }

      [class$="-singleValue"] {
        :hover {
          cursor: pointer;
        }
      }
      [class$="-ValueContainer"] {
        height: 100%;
        position: initial;
      }
      [class$="-IndicatorsContainer"] {
        height: 100%;
        position: initial;
        display: flex;
        align-items: baseline;
        [class$="-indicatorContainer"] {
          svg {
            width: 20px !important;
            height: 20px !important;
          }
        }
      }
      [class$="-option"] {
        cursor: 'pointer';
      }

      [class$="-container"] {
        cursor: 'pointer';
      }

      [class$="-Input"] {
        display: flex;
        [class$="-input"] {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        input {
          height: 100%;
        }
      }

      [class$="-theme"] {
        border-radius: 0.375rem;
      }

      [class$="-menu"] {
        div {
          color: $groupButtonsBorderColor;
          :hover {
            cursor: pointer;
            background-color: $selectMenuItemBackgroundColor;
          }
          :focus {
            border: 1px solid $selectMenuItemFocusBorderColor !important;
          }
        }
      }
    }

    .select-element_invalid {
      [class$="-control"] {
        border: 1px solid $activeDashColor!important;
        box-shadow: none;
        &:focus {
          border: $defaultFocus
        }
      }
    }
  }
}
.cicle-select-scroll-bar {
  ::-webkit-scrollbar {
    width: 0.25rem;
  }
  ::-webkit-scrollbar-thumb {
    background-color: $slideColor;
    border-radius: 0.5rem;
  }
  ::-webkit-scrollbar-track {
    background-color: $backgroundColor;
    border-radius: 0.563rem;
    margin-bottom: 0.625rem;
    margin-top: 0.625rem;
  }
}
