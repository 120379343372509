.loader {
    border: 0.2em solid rgba(0, 0, 0, 0.1);
    border-top: 0.2em solid $loaderBorderColor;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    animation: spin 1.0s linear infinite;

    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }