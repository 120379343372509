.transaction-summary-content {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 8px;
  padding: 0.5rem;

  h1 {
    font-size: 2rem;
    text-align: left;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }

  .transaction-summary-input-container {
    display: flex;
    .summary-info-container {
      width: 50%;
      .summary-info-container-first-row {
        display: flex;
        width: 100%;
        .input-container{
          width: 50%;
        }
      }
      .summary-info-container-second-row {
        display: flex;
        width: 100%;
        .input-container{
          width: 40%;
        }
      }
    }
  }

  .transaction-summary-input-container > .manual-check-reasons-container,
  .outcome-summary-input-container > .rejection-reasons-container {
    width: 50%;
    .input-container {
      textarea {
        width: 100%;
        height: 8rem;
        resize: none;
        padding: .75rem .75rem;
        line-height: 1.5;
        border-radius: 0.35rem;
        font-size: 1rem;
        box-sizing: border-box;
        background: #F4F4F4;
        &::placeholder {
          color: gray;
        }
      }
      span.placeholder {
        position: absolute;
        left: 9px;
        top: -.5rem;
        padding: 0 0.2rem;
        background: $containerBackgroundColor;
      }
      span.display-none {
        display: none;
      }
    }
  }

  .outcome {
    font-size: 1.25rem;
    text-align: left;
    font-weight: 600;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }

  .outcome-summary-input-container {
    display: flex;
    .outcome-container {
      width: 50%;
      .outcome-container-first-row {
        display: flex;
        width: 100%;
        .input-container{
          width: 100%;
          .user-input {
            &.pass::placeholder {
              color: $resultColor;
            }
            &.fail::placeholder {
              color: $resultRejectColor;
            }
          }
        }
      }
      .outcome-container-second-row {
        display: flex;
        width: 100%;
        .input-container{
          width: 100%;
        }
      }
    }
  }
}