$verticalSpace: 2.5rem;
$horizontalSpace: 8rem;

.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  .login-container {
    box-shadow: 0px 2px 4px $loginBoxShadowColor;
    margin: auto;
    min-width: 20vw;
    border-radius: 6px;
    background-color: $containerBackgroundColor;
    padding: $verticalSpace $horizontalSpace 1rem;
    @include flex-direction;
    align-items: center;
    svg {
      width: 80%;
      height: 80%;
    }
    .identity-container {
      @include flex-direction;
      align-items: center;
      .identity-text {
        margin-top: 5px;
        color: $identityTextColor;
        font-size: .9rem;
      }
    }
    .form-container {
      width: 20rem;
      @include flex-direction;
      flex: 1;
      justify-content: center;
      .error-container {
        color: $resultFailedBackground;
        height: 1.25rem;
        margin: 15px;
        font-size: 0.8rem;
        text-align: center;
      }
      .custom-single-buttons-container {
        margin: 0;
        height: 2rem;
        max-height: 2rem;
      }
      .input-container {
        position: relative;
        margin-bottom: 1.2rem;
        input, textarea {
          padding: .5rem .7rem;
          line-height: 1.5;
          width: 20rem;
          border: 1px solid $inputBorderColor;
          border-radius: 0.35rem;
          font-size: 0.9rem;
          color: $inputTextColor;
          box-sizing: border-box;
          background: transparent;
          &::placeholder {
            color: $inputPlaceholderColor;
          }
        }
        .user-input:focus {
          border-color: $inputBorderColorActive;
          box-shadow: 0 1px 1px $loginInputShadowColor inset,
            0 0 1px $inputBorderColorActive;
          outline: 0 none;
          &::placeholder {
            color: transparent;
          }
        }
        .user-input:focus ~ span {
          display: block;
          font-size: 0.9em;
        }
        svg {
          @include icon-size(auto, 1.3rem);
          position: absolute;
          right: 10px;
          top: .9rem;
        }
        span.placeholder {
          display: none;
          position: absolute;
          left: 9px;
          top: -.5rem;
          padding: 0 0.2rem;
          color: $inputBorderColorActive;
          background: $containerBackgroundColor;
        }
        span.display-none {
          display: none;
        }
      }
      .button-container {
        display: flex;
        justify-content: center;
        margin-left: 3.125rem;
        .loader-container {
          position: relative;
          width: 3.125rem;
          background-color: unset;
          .loader {
            position: absolute;
          }
        }
      }
    }
    .forgotten_password_container {
      min-width: 20rem;
      margin-bottom: 15px;
      .horizontal-line {
        margin-bottom: 15px;
      }
      div {
        display: flex;
        justify-content: center;
        span, a {
          white-space: nowrap;
          font-size: 1rem;
        }
        a {
          color: $buttonBackgroundColor;
          text-decoration: underline;
        }
      }
      .forgotten_password_from {
        a {
        border: 1px solid transparent;
        &:focus {
          border: $lightFocus;
        }
        }
      }
    }
  }
}
