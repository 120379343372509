.address-review-summary-content {
  width: 100%;
}
.address-main-content {
  width: 100%;
  display: flex;
  padding: 0 1rem;
  gap: 1rem;
  .address-images-content,
  .address-data-content {
    width: 50%;
  }
  .address-data-content {
    display: flex;
    flex-direction: row;
  }
}