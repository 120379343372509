.main-content {
    width: 100%;
    height: 100%;
    display: flex;
    .logout-container {
      box-shadow: 0px 2px 4px $logoutContainerBoxShadowColor;
      margin: auto;
      border-radius: 6px;
      background-color: $containerBackgroundColor;
      padding: 1rem;
      @include flex-direction;
      align-items: center;
      svg {
        width: 80%;
        height: 80%;
      }
      .log-out-description-container {
        padding: 2rem;
        max-width: 30rem
      }
      .identity-container {
        @include flex-direction;
        align-items: center;
        .identity-text {
          margin-top: 5px;
          color: $identityTextColor;
          font-size: .9rem;
        }
      }
    }
    .button-container {
      display: flex;
      justify-content: center;
      button {
        min-width: 10rem;
        min-height: 2.1rem;
      }
    }
  }