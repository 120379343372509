.address-images-inner-content {
  display: flex;
  flex-direction: row;
  .address-images-right-side {
    width: 50%;
    .address-crop-image-style {
      height: 10rem;
      margin-bottom: .5rem;
      margin-left: .5rem;
    }
    .document-scan-image-style {
      height: 20rem;
      margin: .5rem;
      margin-right: 0;
    }
  }
  .address-images-left-side {
    width: 50%;
    .address-document-image-style {
      height: 40rem;
      margin-right: .5rem;
    }
  }
}