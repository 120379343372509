.lang-section-button {
  width: 5rem;
  height: 2rem;
  .dropdown {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .btn {
      border: 0;
    }
    .dropdown-menu {
      border: none;
      background-color:unset;
      min-width: 0;
      transform: translate(8px, 32px);
      .dropdown-item:hover {
        background-color: unset;
      }
    }
    .dropdown-menu.show {
      display: flex!important;
      flex-direction: column;
      padding: 0;
    }
    .dropdown-toggle {
      outline: unset;
      padding: 0;
      background-color: unset;
      border-radius: 5px;
      border-color: unset;
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-toggle:active {
      background-color: unset;
      border-color: unset;
    }
    .dropdown-toggle:hover {
      border-color: unset;
    }
    .dropdown-toggle:focus {
      box-shadow: unset;
    }
    .dropdown-items {
      padding: 1px, 6px, 1px, 6px;
      width: 4rem;
    }
    .dropdown-items:hover {
      cursor: pointer;
    }
    .dropdown-items-text {
      margin: 1px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      color: $textColor;
    }

    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show>.btn-primary.dropdown-toggle {
      border-color: unset;
      background-color: unset;
      box-shadow: none;
    }

    .active-lang-color {
      background: $activeLangColor;
    }
    .hold-lang-color {
      background: $holdLangColor;
    }
  }
}