.a4-scan-review-content {
  border-radius: 0.3125rem;
  background: #ffffff;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 1rem;
  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }
  .a4-additional-scanned-images-content {
    display: flex;
    flex-wrap: wrap;
    .a4-document-item-content {
      width: 25%;
      display: flex;
      padding: 1rem;
      padding-top: 0;
      flex-direction: column;
      align-items: center;
      .wrapper-transform-component {
        height: 32rem;
      }
    }
  }
  .line {
    border-top: 0.0625rem solid #D6D6D6;
    padding-bottom: 2rem;
  }
  .review-buttons {
    display: flex;
    flex-direction: column;
    .description {
      color: #313030;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
    }
    .a4-reason-selection-section {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      .answer-section {
        display: flex;
        .radio-button {
          height: 1.75rem;
        }
        .radio-button input[type="radio"] {
          width: 1.75rem;
          height: 1.75rem;
          outline: none;
          accent-color: #e9425e;
          cursor: pointer;
        }
      }
      .select-reasons {
        width: 100%;
      }
    }
  }
}