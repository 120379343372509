.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  .login-container {
    .form-container {
      .forgotten-error-container {
        color: $resultFailedBackground;
        height: 1.25rem;
        margin: .5rem;
        font-size: .8rem;
        text-align: center;
      }
      .forgotten-heading {
        font-size: 1rem;
        font-weight: 500;
        margin: .5rem;
      }
      .input-container {
        margin-bottom: .5rem;
      }
      .forgotten-info-message {
        line-height: 1.2;
        font-size: .85rem;
      }
      .button-container {
        margin: 0;
      }
    }
  }
  .forgot-container {
    .identity-container {
      .logo {
        @include icon-size(5rem, 13rem);
      }
    }
    .input-container {
      margin-bottom: 1.2rem;
      &.not-match-password {
        input {
          border-color : $forgottenBorderColor;
        }
      }
    }
    .result-container {
      margin-top: 1rem;
      flex: 1;
      .success-icon {
        @include icon-size(12vh, 12vw);
      }
      .success-text {
        font-size: 2rem;
        font-weight: 500;
        margin: 20px auto;
      }
      .waiting-text {
        font-size: .9rem;
      }
    }
    .back-button {
      margin: 1.3rem auto;
      a:hover {
        color: unset !important;
        text-decoration: default !important;
      }
      .custom-single-buttons-container {
        max-height: 2rem;
        height: 2rem;
        margin: 0;
      }
    }
    .signin-from {
      outline: none;
      a {
      border: 1px solid transparent;
      &:focus {
        border: $lightFocus;
      }
      }
    }
  }
}