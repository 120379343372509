.document-comparison-section {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  .comparison {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .first-image-container {
      width: 100%;
      height: 100%;
      .transform-image-button {
        all: unset;
        cursor: pointer;
        width: 100%;
        height: 100%;
        .image-style {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 12px;
        border: 1px solid #D6D6D6;
        }
      }
    }
    .divisor {
      height: 100%;
      width: 100%;
      background-size: cover;
      position: absolute;
      overflow: hidden;
      bottom: 0;
      background-color: $containerBackgroundColor;
      border-right-width: 1px;
      border-right-style: solid;
      border-image: $vizDivisorColor;
      .image-cover {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 12px;
          border: 1px solid #D6D6D6;
        }
      }
    }
  }
  .comparison-control-section {
    position: absolute;
    width: calc(100% + 17px);
    left: -8.5px;
    height: 4rem;
    margin: 0 auto;
    bottom: 0;
    input[type=range] {
      width: 100%;
      position: relative;
      z-index: 1;
      -webkit-appearance: none;
      height: 100%;
      appearance: none;
      outline: none;
      margin: 0 auto;
      background: $vizInputRangeColor;
      cursor: ew-resize;
      pointer-events: none;
    }
    input[type=range]::-webkit-slider-thumb {
      background-image: url("../../images/slide.svg");
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $vizInputRangeBackgroundColor;
      width: 17px;
      height: 65px;
      border-radius: 9px;
      box-shadow: 0px 2px 4px $vizInputRangeBoxShadowColor;
      pointer-events: auto;
      appearance: none;
      cursor: ew-resize;
      -webkit-appearance: none;
    }
    input[type=range]::-moz-range-thumb {
      background-image: url("../../images/slide.svg");
      background-size: 12px;
      background-repeat: no-repeat;
      background-position: center;
      background-color: $vizInputRangeBackgroundColor;
      width: 17px;
      height: 65px;
      border-radius: 9px;
      box-shadow: 0px 2px 4px $vizInputRangeBoxShadowColor;
      pointer-events: auto;
    }
    .inputRange::-moz-focus-inner {
      border: 0;
    }
    .inputRange::-moz-focus-outer {
      border: 0;
    }
    input[type=range]::-webkit-slider-runnable-track {
      background: transparent;
    }
    .copyright-content {
      .copyright-text {
        font-size: .94rem;
      }
      .copyright-external-link {
        text-decoration: underline;
        color: $styledTextDescriptionColor;
        cursor: pointer;
        border: 0;
        background-color: transparent;
      }
    }
  }
}
