.data-verification {
  position: relative;
  .no-data-verification {
    color: $secondaryButtonHoverTextColor;
    font-size: 1.5rem;
    margin-top: 3rem;
  }
  .verification-loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10rem;
    .loader {
      @include icon-size(2.5rem, 2.5rem);
    }
  }
  h2 {
    font-size: 1.125rem;
    font-weight: 500;
    text-align: start;
    margin: 1.25rem 0 0.625rem;
  }

  .verification-table {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-bottom: 1.25rem;
    width: 100%;

    .verification-filed-row {
      display: flex;
      width: 100%;

      span {
        font-size: 0.875rem;
        margin-left: 24%;
        text-align: start;
      }

      span:nth-child(2) {
        margin-left: 42%;
      }
    }

    .single>span {
      margin-left: 28%;
    }

    .verification-row {
      display: contents;
    }
  }
  .loader-container {
    margin-top: 3rem;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $contentBackgroundCount;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}