@mixin icon-size ($height: 20px, $width: 20px) {
    height: $height;
    width: $width;
}

@mixin flex-align-center {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-direction {
    display: flex;
    flex-direction: column;
}

