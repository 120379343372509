$fontFamily: "Montserrat", sans-serif;
$inputFontFamily: "Roboto Mono", monospace;
$backgroundColor: #e4e4e4;
$contentBackgroundCount: #fbfbfb;
$containerBackgroundColor: #fbfbfb;
$identityTextColor: #37333b6b;
$popUpColor: black;
$helpLinkColor: #00a1ed;

$secondaryButtonTextColor: #707070;
$secondaryButtonHoverTextColor: #e9425e;
$secondaryButtonHoverBorderColor: #e9425e;
$outlineSecondaryDisabledColor:#BFBEC0;
$outlineSecondaryDisabledMainColor:#fff;
$copyButtonColor: #9dd9ff;

$textColor: #fff;
$codeColor: #e9425e;

$buttonBackgroundColor: #e9425e;
$buttonBackgroundColorHover: #c43657;
$buttonOutlineBackgroundColor: #fff;
$buttonFocusShadow: #ffffffcc;
$inactiveButtonBackgroundColor: #bbb;
$buttonBoxShadow: 0px 3px 6px #00000029;
$activeButtonColor: #e9425e;
$transparentBackground: transparent;

$groupButtonsBorderColor: #37333b;
$activeButtonColorHover: #c43657;

$crossMarckColor: #ff4d4d;
$tickMarkColor: #4caf50;

$resultFailedBackground: #e9425e;
$shieldColor: #c43657;
$dataTextColor: #37333b;
$styledTextDescriptionColor: red;
$checkboxColor: #FD195A;
$matchingTextColor: #064;
$notMatchingTextColor: red;
$copiedInputColor: #ffcc40;

$tableHoverBackground: #f5f2f2;
$tableSelectedBackground: #f4f2f2;
$tableBorderColor: #ddd;
$tableSelectedBorderColor: #e9425e;
$rightMenuSelectedBackgroundColor: rgba(233, 68, 95, 0.3);
$tableSearchInputTextColor: #1a1818;
$tableSearchInputBorder: #dddddd;
$tableSearchInputBackground: #e7e7e7;
$fieldStatusColor: #E9425E;
$leftMenuBorderColor: #37333B1A;

$inputBorderColor: #37333b;
$inputBorderColorActive: #00a1ed;
$inputPlaceholderColor: #37333bcc;
$inputTextColor: #313030;
$bigModalInput: black;
$bigModalTitleColor: black;

$loaderBorderColor: #e9425e;

$modalBackground: #fff;
$modalSearchTextColor: #fff;
$modalBorderRadius: 5px;
$modalShadow: 0px 3px 6px #00000029;
$modalFailColor: #e9425e;
$bigModalBackgroundColor: #e9425e;
$bigModalTextColor: #fff;
$modalMessageTextColor: #ffffffcc;
$modalBorderColor: #d0cccc;
$modalCancelButton: #b71c1c;
$modalContinueButton: #1b5e20;
$btnCancelContinueColor: white;
$closeModalBtnColor: black;
$searchInputBorderColor: grey;

$videoPlayerButtonBackgroundColor: rgba(233, 66, 94, 0.2);
$videoPlayerFooterBackgroundColor: rgba(49, 48, 48, 0.5);
$videoPlayerCurrentTimeColor: #e9425e;
$videoPlayerSecondsBackgroundColor: rgba(0, 0, 0, 0.2);
$videoPlayerSecondsCoverColor: rgba(0, 0, 0, 0);
$videoPlayerNoRenderingBackgroundColor: black;
$videoIconFillColor: #fff;
$videoInputRangeBoxShadowColor: #000000d3;
$videoInputRangeColor: #000;
$videoPlayerFullscreenIconColor: #ccc;

$vizDivisorColor: linear-gradient(180deg, #ffffff 0%, #ffffff 54%, #e9425e 86%, #e9425e) 0.7 100%;
$vizInputRangeBoxShadowColor: #000000d3;
$vizInputRangeColor: rgba(0, 0, 0, 0);
$vizInputRangeBackgroundColor: #e9425e;

$activeDashColor: #e9425e;
$slideColor: #a3a3a3;
$fieldItemBorderColor: #0000006c;
$unsureMessageColor: #f7943e;
$highLightColor: #00ffb1;

$dashboardBackground: #37333b;
$resultColor: #04822a;
$resultRejectColor: #a62636;
$historyScrollBarColor: #c8c7c8;
$dashboardOverlapAreaBackground: #000000;
$newTransactionsContainerBorder: #c8c7c8;
$buttonContainerDisabledColor: #fff;
$newTransactionsTextColor: #37333b;
$switchButtonBorderColor: #ddd;
$switchButtonColor: black;

$pgnItemColor: #707070;

$dataTableBackgroundColor: #ffffff;

$defaultFocus: 2px solid #c3c3c3;
$lightFocus: 1px solid #c3c3c3;
$inputFocus: 1px solid #58b0e4d1;

$toolTipBackgroundColor: #37333b;
$toolTipInnerColor: #fff;

$backgroundWhite: #ffffff;
$BigModalTextColorWhite: #ffffff;
$contentTextColor: black;

$borderRow: #d8d8d8;
$backgroundRow: #e4e4e485;
$notCompleted: #ff002b;
$accordionTitleColor: #37333B;
$accordionFiledDanger: #C43657;
$accordionFiledSuccess: #04822A;

$selectElementBorderColor: #37333bcc;
$selectMenuItemBackgroundColor: #e0e0e0;
$selectMenuItemFocusBorderColor: black;

$activeLangColor: #37333bcc;
$holdLangColor: #a3a3a3;

$calendarIconColor: #313030;

$dlColor: #37333BCC;

$notificationsColor: #00A1ED;
$topHeaderIconBorderColor: #c3c3c3c3;
$imageBorderColor: #ddd;

$infoBarTooltipBackground: #ddd;

$loginBoxShadowColor: #0000006C;
$loginInputShadowColor: rgba(0, 0, 0, 0.075);

$logoutContainerBoxShadowColor: #0000006C;

$forgottenBorderColor: red;

$imageContainer: #F5F5F5;
$labelContainerColor: grey;

$paginationArrowColor: #E9425E;

$bmCrossBackground: #bdc3c7;

$footerBackgroundColor: #444;
$footerTextColor: #fff;
$footerItemsBorderColor: #fff;

$tabButtonActiveColor: black;
$tabButtonInactiveColor: white;
$tabBackgroundColor: #f1f1f1;
