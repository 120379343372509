.default-acordion-style{
  .accordion {
    background-color: $leftMenuBorderColor;
    .card {
      border-radius: 0;
      border-bottom: 1px solid $borderRow;
      border-top: 1px solid transparent;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      cursor: pointer;
      .card-header {
        margin: 0;
        padding: 0;
        &:hover {
          background-color: $backgroundRow;
        }
        .accordion-title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 15px;
          border: 1px solid transparent;
          background-color: $backgroundWhite;
          &:focus {
            outline: none;
            border: 1px solid $inputFocus;
          }
          .left-info {
            display: flex;
            align-items: center;
            .accordion-title-info {
              margin: 0 25px;
            }
            svg {
              width: 1rem;
              height: 1rem;
            }
          }
          .disabled {
            color: $slideColor;
            svg {
              g {
                path {
                  fill: $slideColor;
                }
              }
            }
          }
          .status-title {
            display: flex;
            align-items: center;
            justify-content: center;
            color: $accordionTitleColor;
            .warning {
              color: $unsureMessageColor;
            }
            .danger {
              color: $accordionFiledDanger;
            }
            .success {
              color: $accordionFiledSuccess;
            }
            .right-text {
              letter-spacing: -0.39px;
              font-size: 0.8125rem;
              margin-right: 0.625rem;
            }
            .success {
              path {
                fill:$resultColor;
              }
            }
          }
        }
      }
    }
  }
}
.card-header:focus-visible {
  outline: none !important;
  background-color: $backgroundRow;
}