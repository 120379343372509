.wrapper-transform-component {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    .loader {
      @include icon-size(2.5rem, 2.5rem);
    }
  }
  .transform-component {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .wrapper-image-container {
      overflow: hidden;
      border: 1px solid $imageBorderColor;
      border-radius: 20px;
      height: 100%;
      width: 100%;
      display: flex;
      position: relative;
      .react-transform-element {
        width: 100%;
        height: 100%;
      }
      .react-transform-component {
        width: 100%;
        height: 100%;
        .react-transform-element {
          justify-content: center;
          align-items: center;
          .loader-container {
            margin-top: 0;
            position: absolute;
            height: 100%;
          }
          .transform-image-button {
            all: unset;
            cursor: pointer;
            width: 100%;
            height: 100%;
            position: relative;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
              background-size: cover;
            }
            .high-light-style {
              border: 2px solid $highLightColor;
              border-radius: 4px;
              opacity: 1;
            }
          }
        }
      }
    }

    .tools {
      display: flex;
      height: 2rem;
      margin-top: 10px;
      justify-content: center;
      .is-disabled {
        pointer-events: auto;
        opacity: 0.2;
        &:hover svg {
          cursor: not-allowed;
        }
      }
      .zoom{
        width: 2rem;
        height: 2rem;
        margin: 0 5px;
        svg {
          width: 100%;
          height: 100%;
          cursor: pointer;
        }
      }
      .zoom:hover {
        cursor: pointer;
      }
    }
  }
}