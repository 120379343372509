.info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem;
  margin-left: .5rem;
  margin-right: .5rem;
  border-radius: 0.3125rem;
  font-size: .8rem;
  background-color: $dataTableBackgroundColor;
  div {
    position: relative;
    border: 0px solid transparent;
    border-radius: 0.3125rem;
    svg {
      vertical-align: middle;
    };
  }
  .tab-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $tabBackgroundColor;
    overflow: hidden;
    margin: 0 auto;
  }
  .info_bar_tr_code {
    color: $codeColor;
    margin: 0 0.625rem;
    font-family: $inputFontFamily;
    font-size: 1.2rem;
  }
  .into_bar_text {
    margin: 0;
    display: contents;
  }
  .copy-button {
    svg {
      height: 1.3rem;
      width: 1.3rem;
    }
    :focus {
      outline: none;
      height: 1.4rem;
      width: 1.4rem;
    }
    cursor: pointer;
    .reg-tooltip {
      background: $infoBarTooltipBackground;
      white-space: nowrap;
      border-radius: 5px;
      position: absolute;
      margin: 0.3125rem;
      padding: 0.3125rem;
      top: -0.5625rem;
      display: inline;
      z-index: 1;
    }
    .reg-tooltip-hide {
      display: none;
    }
  }
  .tab-button {
    max-height: 2rem;
    height: 2rem;
    width: 12.5rem;
    border: none;
    background-color: $tabButtonInactiveColor;
    color: $tabButtonActiveColor;
    font-size: .8rem;
    cursor: pointer;
    border: 0.063rem solid $tabButtonActiveColor;
    transition: background-color 0.3s ease;
    &:first-child {
      border-top-left-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
    &:last-child{
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    }
    &.active {
      background-color: $tabButtonActiveColor;
      color: $tabButtonInactiveColor;
    }
    @media (max-width: 300px) {
      font-size: 0.875rem;
      padding: 0.5rem 0.938rem;
    }
  }
  .info-bar-item:first-child {
    display: flex;
    width: 330px;
    justify-content: flex-start;
    align-items: center;
  }
  .status {
    width: 330px;
    display: flex;
    justify-content: flex-end;
    position: relative;
    align-items: center;
    max-height: 2.25rem;
    height: 2.25rem;
    .button {
      width: 1.4rem;
      svg {
        height: 1.4rem;
        width: 1.4rem;
      }
      :focus {
        height: 1.5rem;
        width: 1.5rem;
        outline: none;
      }
    }
  }
}