.description-text {
  .link {
    text-decoration: underline;
    color: $styledTextDescriptionColor;
    cursor: pointer;
    border: 0;
    background-color: transparent;
  }

  .bold {
    font-weight: bold;
  }
}