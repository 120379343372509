.burger-menu-main-component {
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    margin: 10px;
  }

  .bm-burger-bars {
    background: $dlColor;
  }

  .bm-burger-bars-hover {
    background: $fieldStatusColor;
  }

  .bm-cross-button {
    height: 16px;
    width: 16px;
    right: 0px !important;
    top: 0px !important;
  }

  .bm-cross {
    background: $bmCrossBackground;
  }

  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 400px !important;
  }

  .bm-menu {
    height: 90% !important;
    background: $backgroundColor;
    padding-top: 24px;
    font-size: 1rem;
  }

  .bm-morph-shape {
    fill: $backgroundColor;
  }

  .bm-item-list {
    .left-menu-container {
      padding: 0 5px;
      width: 100% !important;
      margin: 0;
      .left-menu-accordions-container {
        .left-menu-container-accordion-field {
          padding-left: 0;
        }
      }
    }
  }

  .bm-item {
    display: inline-block;
  }

  .bm-overlay {
    background: transparent !important;
  }
}
