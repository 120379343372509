.top-header-container {
  background-color: $backgroundColor;
  padding: 5px 10px;
  @include flex-align-center;
  .logo {
    flex-direction: column;
    p {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 0;
      margin-right: 18px;
      margin-top: -3px;
    }
  }

  div {
    display: flex;
    img {
      height: 30px;
    }
  }

  .list-logo-container {
    svg {
      @include icon-size(1.8rem, 1.8rem);
    }
  }

  .user-logo-container {
    align-items: center;
    justify-content: flex-end;
    .review-mode-section {
      font-size: .875rem;
      font-weight: 500;
      color: $dataTextColor;
    }
    span {
      font-size: 15px;
    }
    svg.Capa_1 {
      // @include icon-size;
      height: 20px;
      fill: $notificationsColor;
      margin-right: 10px;
    }

    svg {
      padding-left: .5rem;
    }

    .person-icon-container,
    .settings-icon-container {
      position: relative;
      width: 3rem;

      &:hover {
        cursor: pointer;
      }

      svg.person-icon {
        height: 2rem;
        width: 45px;
        padding: 0;
        &:focus {
          border: $defaultFocus;
          outline: none;
          border-radius: 5px;
        }
      }

      .menu-dropdown {
        display: block;
        position: absolute;
        right: 0;
        top: 30px;
        min-width: 120px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        outline: none;
        &:focus {
          outline: none;
        }
        .menu-dropdown-item {
          display: block;
        }
        .popup-content {
          background-color: $backgroundColor;
          color: $popUpColor;
          padding: 8px;
          text-decoration: none;
          display: block;
          font-size: .8em;
          border: 2px solid transparent;
          &:hover {
            background-color: $tableBorderColor;
          }
          &:focus {
            border: $defaultFocus;
            outline: none;
          }
        }
      }
    }

    .settings-icon-container {
      display: flex;
      justify-content: center;
      width: 25px;
    }

    .vertical-dots-icon {
      height: 25px;
      padding: 0;
      &:focus {
        outline: none;
        border: 2px solid $topHeaderIconBorderColor;
        border-radius: 5px;
      }
    }

    span {
      color: $notificationsColor;
      font-size: .9rem;
    }
  }
}