.date-picker-main {
  width: 100%;
  display: inline-block;
  margin: 0;
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      margin-bottom: 0px !important;
      div {
        .react-datepicker {
          .react-datepicker__triangle {
            left: 50% !important;
          }
        }
      }
    }
  }
  .date-picker-item {
    width: 100%;
    padding: 8px;
    border: 1px solid $selectElementBorderColor;
    border-radius: 6px;
    resize: vertical;
    font-size: 18px;
    letter-spacing: 0px;
    color: $inputPlaceholderColor;
    opacity: 1;
  }
  .error-border {
    border: 1px solid $activeDashColor !important;
  }
  .default-border {
    border: 1px solid $selectElementBorderColor;
  }
}

.custom-calendar-container {
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .custom-calendar-element {
    display: flex;
    justify-content: space-between;
    padding: 7px 25px;
    align-items: center;
    margin: 1.5px 0px;
    background-color: rgb(230, 230, 230);
    svg {
      height:13px;
      cursor: pointer;
    }
    .custom-calendar-year {
      border: 1px solid rgb(216, 212, 212);
      border-radius: 2px;
      position: relative;
      svg {
        width: 15%;
        position: absolute;
        height: 55%;
        right: 2px;
        top: 4px;
        fill: $calendarIconColor;
        height: 10px;
        cursor: default;
      }
      input {
        text-align: center;
        background-color: transparent;
        width: 70px;
        height: 16px;
        border: 0px;
        -moz-appearance: textfield;
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  }