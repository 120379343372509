.ta-review-table {
  margin: 10px auto;
  border: 1px solid $tableBorderColor;
  border-radius: 7px;
  width: 100%;
  border-spacing: 0;
  text-align: left;
  .table-heading {
    display: flex;
    justify-content: space-between;
  }
  .table-footer {
    padding: .6rem 1rem;
  }
  thead {
    tr:nth-child(1) {
      font-size: 1.2rem;
    }
    tr:nth-child(2) {
      font-size: .88rem;
      th {
        border-bottom: 1px solid $tableBorderColor;
        font-weight: normal;
      }
    }
    th {
      padding: .6rem  1rem;
      input {
        padding: 3px 10px;
        font-size: 15px;
        width: 235px;
        float: right;
        background: $tableSearchInputBackground 0% 0% no-repeat padding-box;
        background-image: url(../../images/loupe.svg);
        background-repeat: no-repeat;
        background-position: 95% center;
        background-size: 1rem;
        border: 1px solid $tableSearchInputBorder;
        border-radius: 15px;
        outline: none;
        color: $tableSearchInputTextColor;
        font-weight: normal;
      }
    }
  }
  tbody {
    tr {
      font-size: .94rem;
      font-weight: normal;
      td {
        padding: .5rem 1rem;
        border-top: 1px solid $tableBorderColor;
        .dataset-name-content {
          display: flex;
          p {
            margin: 0;
            margin-right: 2rem;
          }
        }
        .custom-single-buttons-container {
          margin: 0;
          &:focus {
            border-color: 1px solid $tableBorderColor;
          }
          .outline-secondary {
            display: flex;
            justify-content: center;
            .btn-outline-secondary {
              width: max-content;
              display: flex;
              justify-content: center;
              margin: 0;
              height: 1.5rem;
              font-size: 1rem;
              font-weight: 400;
              &:disabled {
                opacity: 1;
                background-color: $outlineSecondaryDisabledColor;
                border-color: $outlineSecondaryDisabledColor;
                color: $outlineSecondaryDisabledMainColor;
              }
            }
          }
        }
      }
      .result {
        color: $resultColor !important;
      }
      .error {
        color: $resultRejectColor !important;
      }
      td:first-child {
        border-left: 3px solid transparent;
      }
      .ellipsis {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: fit-content;
        max-width: 100%;
        padding: 0;
        margin: 0;
        width: fit-content;
        &:hover {
          cursor: pointer;
        }
      }
      &.selected,
      &:hover {
        cursor: pointer;
        background-color: $tableSelectedBackground;
        td:first-child {
          border-left: 3px solid $tableSelectedBorderColor;
        }
      }
    }
    .empty {
      &:hover {
        cursor: default;
        background-color: transparent;
        border-left: 1px solid $tableBorderColor;
      }
      .empty-message {
        border-left: 3px solid transparent !important;
      }
    }
    .empty-message {
      background-color: transparent;
      border-bottom: 1px solid transparent;
    }
    .no-connected-dataset-tr {
      color: $secondaryButtonHoverTextColor;
      margin-top: 3rem;
      text-align: center;
      border: none;
      &:hover {
        cursor: default;
        background-color: transparent;
        border-left: 1px solid $tableBorderColor;
      }
      .no-connected-dataset-td {
        font-size: 1.5rem;
      }
      .no-connected-dataset-td:first-child {
        border: 3px solid transparent;
      }
    }
    .table-loader {
      &:hover {
        border: none;
        cursor: default;
        background-color: transparent;
        td:first-child {
          border: none;
        }
      }
      .loading-container {
        width: 100%;
        min-height: 10rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .loader {
          width: 4rem;
          height: 4rem;
        }
      }
    }
  }
  tfoot {
    tr:nth-child(1) {
      font-size: 1.2rem;
    }
    tr:nth-child(2) {
      font-size: .88rem;
      th {
        border-bottom: 1px solid $tableBorderColor;
        font-weight: normal;
      }
    }
    td {
      padding: .6rem  1rem;
    }
  }
  tr {
    border: 1px solid $tableBorderColor;
  }

}