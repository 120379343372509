.address-review-content {
  border-radius: 0.3125rem;
  background: white;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0 1rem;
  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }
  .address-main-content {
    display: flex;
    .address-images-content {
      width: 50%;
      height: 100%;
    }
    .address-data-content {
      width: 50%;
      display: flex;
      flex-direction: row;
    }
  }
  .address-result-content {
    .review-buttons {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
      .description {
        color: #313030;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin: 0;
        padding: 1rem 0;
      }
      .result-reasons-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        .answer-section {
          display: flex;
          justify-content: flex-end;
          .radio-button {
            height: 1.5rem;
          }
          .radio-button input[type="radio"] {
            width: 1.75rem;
            height: 1.75rem;
            outline: none;
            accent-color: #e9425e;
            cursor: pointer;
          }
        }
        .select-reasons {
          width: 100%;
          .multi-select-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            .multi-select-item {
              width: 30%;
              p {
                font-size: 0.675rem;
                text-align: start;
                margin-bottom: 0.15rem;
              }
            }
          }
        }
      }
    }
  }
}