@import 'vars.scss';
@import 'mixins.scss';
@import "react-image-gallery/styles/scss/image-gallery.scss";
@import 'components/header/TopHeader.scss';
@import 'components/loader/Loader.scss';
@import 'components/table/Table.scss';
@import 'pages/dashboard/Modals/Modal.scss';
@import 'components/videoPlayer/VideoPlayer.scss';
@import 'components/select/SelectOption.scss';
@import 'components/comparison/Comparison.scss';
@import 'components/bigModal/BigModal.scss';
@import 'components/accordionField/AccordionField.scss';
@import 'components/buttonTypes/ButtonTypes.scss';
@import 'pages/signIn/SignIn.scss';
@import 'pages/signIn/ExternalSignIn.scss';
@import 'pages/forgotPassword/ForgotPassword.scss';
@import 'pages/newPassword/NewPassword.scss';
@import 'pages/logout/LogOut.scss';
@import 'pages/taBoard/TABoarding.scss';
@import 'pages/dashboard/Dashboard.scss';
@import 'pages/dashboard/LockTransaction.scss';
@import 'components/toolTipField/ToolTipField.scss';
@import 'components/datePicker/DatePicker.scss';
@import 'components/styledText/StyledText.scss';
@import 'components/lang/SwitchLanguage.scss';
@import 'components/imageWrapper/ImageWrapper.scss';
@import 'pages/review/Review.scss';
@import 'pages/review/footer/Footer.scss';
@import 'pages/review/infoBar/InfoBar.scss';
@import 'pages/identityReview/IdentityReview.scss';
@import 'pages/identityReview/liveness/Liveness.scss';
@import 'pages/identityReview/faceVerification/FaceVerification.scss';
@import 'pages/identityReview/documentValidation/DocumentValidation.scss';
@import 'pages/identityReview/documentValidation/dataVerification/DataVerification.scss';
@import 'pages/addressReview/AddressReview.scss';
@import 'pages/addressReview/addressImages/AddressImages.scss';
@import 'pages/addressReview/addressData/AddressData.scss';
@import 'pages/a4ScanReview/A4ScanReview.scss';
@import 'components/modal/Modal.scss';
@import 'components/modal/Specimen.scss';
@import 'pages/identityReview/documentValidation/dataVerification/drivingField/DrivingField.scss';
@import 'pages/identityReview/documentValidation/dataVerification/fieldComparison/FieldComparison.scss';
@import 'components/burgerMenu/BurgerMenu.scss';
@import 'pages/summary/Summary.scss';
@import 'pages/summary/addressReview/AddressReview.scss';
@import 'pages/summary/dataVerification/DataVerification.scss';
@import 'pages/summary/documentValidation/DocumentValidation.scss';
@import 'pages/summary/faceVerification/FaceVerification.scss';
@import 'pages/summary/scannedDocument/ScannedDocument.scss';
@import 'pages/summary/selfieVideo/SelfieVideo.scss';
@import 'pages/summary/transactionSummary/TransactionSummary.scss';

body, html, button, input, div, textarea {
  font-family: $fontFamily;
}

body {
  overflow-x: hidden;
}

.App {

  div[role=button]:active,
  div[role=button]:focus
    {
      outline: 0;
    }

  .button {
    cursor: pointer;
    outline: none;
  }

  img {
    pointer-events: none;
  }

  a:-webkit-any-link:focus {
    outline: none;
  }

  text-align: center;
  background-color: $backgroundColor;
  @include flex-direction;
  flex-shrink: 0;
  height: 100%;

  .container {
    display: flex;
    overflow-y: auto;
    flex: 1;
  }

  .content {
    @include flex-direction;
    overflow: hidden;
    position: relative;
    height: 100%;
    h3 {
      margin: 10px auto;
    }

    .loader-container {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background: $contentBackgroundCount;
      z-index: 999;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .check-content {
    display: flex;
    justify-content: space-between;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    .main-container {
      width: 50%;
      display: flex;
      justify-content: center;
    }
    .extra-space-menu-container {
      width: 25%;
    }
  }
  .loader-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: $contentBackgroundCount;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toastContainer  {
    .Toastify__toast {
      border-radius: 0.3rem;
    }
    .Toastify__toast--error {
      background: $codeColor;
      font-size: 1rem;
    }

    .Toastify__progress-bar {
      background-color: $containerBackgroundColor;
    }

    .Toastify__toast-body {
      margin: 0;
      flex: initial;
      padding: 3px;
    }

    .Toastify__progress-bar {
      height: 2px;
    }
  }
}

.connection-lost-modal .modal-content {
  background-color: $bigModalBackgroundColor;
  color: $bigModalTextColor;
  border-radius: 0.625rem;
  .connection-lost {
    margin: 2rem 0;
    font-size: 1.5rem;
  }
}

.error-msg {
  color: $codeColor;
  margin: 20px 0;
}

::-webkit-scrollbar {
  width: 0.25rem;
}
::-webkit-scrollbar-thumb {
  background-color: $slideColor;
  border-radius: 0.5rem;
}
::-webkit-scrollbar-track {
  background-color: $backgroundColor;
  border-radius: 0.563rem;
  margin-bottom: 0.625rem;
  margin-top: 0.625rem;
}
