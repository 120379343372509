
.document-validation-section {
  width: 100%;
  border-radius: 0.3125rem;
  margin: 0 0.625rem;
  padding: 0 1rem;
  margin-bottom: 2rem;

  h1 {
    font-size: 1.25rem;
    font-weight: 600;
    text-align: left;
    margin: 0 0.625rem;
    line-height: 1.5rem;
    padding: 1.2rem 0;
  }

  .clarifier-selection-section {
    display: flex;
    align-items: center;
    gap: 1.25rem;
    margin: 0 0.625rem;

    div > p {
      text-align: left;
      font-size: 0.8rem;
      font-weight: 500;
      margin: 0;
    }

    .select-container {
      width: 15.625rem;
    }
  }

  .document-validation {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .document-validation-image-section {
      display: flex;
      justify-content: space-between;
      width: 65%;

      .document-validation-image-container {
        display: flex;
        flex-direction: column;
        width: 48%;

        .image-wrapper-container {
          height: 20rem;
        }

        .doc-validation-modal-button {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          width: 100%;
          height: 2rem;
          svg {
            max-width: 1.5rem;
            width: 1.5rem;
            height: 100%;
            margin-top: 0.5rem;
            cursor: pointer;
          }
          .is-disabled {
            pointer-events: auto;
            opacity: 0.2;
          }

          &:hover .is-disabled {
            cursor: not-allowed;
          }
        }
        .document-validation-image-comparison {
          margin-top: .5rem;
          margin-bottom: 3rem;
          width: 100%;
          height: 20rem;
        }

        h2 {
          font-size: 1.125rem;
          font-weight: 500;
          text-align: start;
          margin: 1.25rem 0 0.625rem;
        }

        .video-player-section {
          width: 100%;
          height: 20rem;
          position: relative;

          .video-player {
            .figcaption {
              .progress-bar {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                position: relative;
                background-color: transparent;
              }
            }
          }
        }
      }
    }

    .document-validation-verification-section {
      width: 35%;
      margin-left: 2.5rem;
      h2 {
        width: 80%;
        font-size: 1.125rem;
        font-weight: 500;
        text-align: start;
        margin: 1.25rem 0 1.2em;
      }

      .zoom-icons-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin: 1.25rem 0 0.625rem;

        .zoom {
          width: 1.5rem;
          height: 1.5rem;
          margin: 0 0.3125rem;

          svg {
            width: 100%;
            height: 100%;
            cursor: pointer;
          }
        }

        .is-disabled {
          pointer-events: auto;
          opacity: 0.2;

          &:hover svg {
            cursor: not-allowed;
          }
        }

        h2 {
          width: 80%;
          font-size: 1.125rem;
          font-weight: 500;
          text-align: start;
        }

        svg {
          width: 10%;
          height: 1.25rem;
          cursor: pointer;
        }
      }

      .image-wrapper-container {
        width: 100%;
        height: 12rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .review-section-container {
    width: 100%;
    height: auto;
    .line {
      border-top: 0.0625rem solid #D6D6D6;
      margin: 1.5rem 0;
    }
    h2 {
      font-size: 1.125rem;
      font-weight: 500;
      text-align: start;
      margin: 1.25rem 0 0.625rem;
    }
    .checkbox-section {
      display: flex;
      .review-description {
        color: #313030;
        font-size: 13px;
        font-weight: 400;
        line-height: 15.85px;
        text-align: left;
        margin-left: .5rem;
        svg {
          width: 1.125rem;
          height: 1.125rem;
        }
        svg:hover {
          cursor: pointer;
        }
      }
      input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
      }
      input[type="checkbox"]:checked {
        accent-color: $checkboxColor;
      }
    }
    .review-buttons {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .description {
        color: #313030;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
      }
      h2 {
        flex: 1 1 50%;
      }
      .answer-section {
        display: flex;
        justify-content: flex-end;
        flex: 1 1 50%;
        .radio-button {
          height: 1.5rem;
        }
        .radio-button input[type="radio"] {
          width: 1.75rem;
          height: 1.75rem;
          outline: none;
          accent-color: #e9425e;
          cursor: pointer;
        }
      }
      .select-reasons {
        width: 100%;
        .multi-select-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .multi-select-item {
            width: 30%;
            p {
              font-size: 0.675rem;
              text-align: start;
              margin-bottom: 0.15rem;
            }
          }
        }
      }
    }
  }
}

.document-template {
  text-align: center;
  font-family: Arial, sans-serif;

  .document-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0;

    .document-item {
      margin-right: 20px;

      .document-image {
        width: 250px;
        height: auto;
        border: 1px solid #ccc;
        border-radius: 5px;
      }

      p {
        margin-top: 10px;
      }
    }

    .template-choices {
      display: flex;

      .template-item {
        margin: 0 10px;

        .template-image {
          width: 200px;
          height: auto;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        p {
          margin-top: 10px;
        }
      }
    }
  }

  .template-selection {
    p {
      margin-bottom: 10px;
    }

    .template-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;

      .template-button {
        background-color: #f0f0f0;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px 20px;
        margin: 0 5px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.selected {
          background-color: #f66;
          color: white;
        }

        &:hover {
          background-color: #ddd;
        }
      }
    }

    .select-button {
      background-color: #f66;
      color: white;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #d55;
      }
    }
  }
}
