.template-container {
  width: 100%;
  .document-template-image-container {
    margin: 5px 0;
    border: 2px solid $imageContainer;
    border-radius: 10px;
    padding: .625rem;

    .image-container-doc {
      height: 100%;
      position: relative;

      .left-arrow {
        top: 0;
        bottom: 0;
        left: 0;
      }

      .right-arrow {
        top: 0;
        bottom: 0;
        right: 0;
      }

      .arrow-container {
        width: 15px;
        height: 15px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        cursor: pointer;
      }

      .images-container {
        display: flex;
        justify-content: center;
        padding-left: 50px;
        padding-right: 50px;

        .image-item {
          padding: .125rem .5rem;
          flex: 1;
          width: 100%;
          height: 16rem;
        }
      }
    }

    .label-container {
      font-size: 14px;
      color: $labelContainerColor;
      padding: 5px;
    }

    .template-not-available {
      color: $buttonBackgroundColor;
      font-size: x-large;
    }
  }
}

.template-loader-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: .5px;
}

.template-right-menu {
  text-align: left;
  font-size: .9rem;

  .header {
    font-weight: 600;
    font-size: 16px;
  }

  .menu-image-container {
    margin-top: 15px;
    margin-bottom: 15px;

    .menu-image {
      margin: .5rem 0;
      border-collapse: separate;
      border-spacing: 5px 10px;

      img {
        width: 2.5rem;
        height: 1.5rem;
      }
    }
  }
}

.main-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  max-height: 2rem;
  height: 2rem;
  .arrow-container svg {
    height: 2rem;
    width: 2rem;
    fill: $buttonBackgroundColor;
  }
  .disabled svg {
    fill: $secondaryButtonTextColor;
  }
  .footer-buttons-block {
    .custom-group-buttons-container .btn {
      font-size: .825rem;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.specimen-message-content {
  margin-bottom: 1.5rem;
}