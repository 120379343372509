.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  .container {
    .new-password-container {
      .set-password-heading {
        font-size: 1rem;
        font-weight: 500;
        margin: .5rem;
      }
      .form-container {
        .password-requirements-content {
          display: flex;
          flex-direction: column;
          text-align: start;
          margin-bottom: 0.8125rem;
          .valid-option {
            width: 20rem;
            background-color: rgb(227, 248, 235);
            .correct-icon-section {
              width: 0.8125rem;
              height: 0.8125rem;
              margin: 0.25rem;
              fill: #196535;
            }
            .pswd-requirements-option {
              color: #196535;
              font-size: 13px;
              font-weight: bold;
              text-align: left;
              width: 20rem;
            }
          }
          .error-option {
            width: 20rem;
            .pswd-requirements-error {
              color: #797979;
              font-size: 13px;
              font-weight: bold;
              text-align: left;
            }
          }
        }
        .new-pswd-error-container {
          color: $resultFailedBackground;
          line-height: 1;
          margin-bottom: 0.8125rem;
          font-size: 0.8rem;
          text-align: center;
        }
      }
    }
  }
}