.selfie-video-container-summary {
  height: 41rem;
  width: 100%;
  padding: 0 1rem;
  .video-status {
    font-size: 0.75rem;
    font-weight: 600;
    text-align: left;
    .fail {
      padding-left: 0.5rem;
      color: $resultRejectColor;
    }
    .pass {
      padding-left: 0.5rem;
      color: $resultColor;
    }
  }
  .video-section {
    height: 38rem;
    width: 100%;
    position: relative;
  }
}