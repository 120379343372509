.footer-buttons-block {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  margin-bottom: 0.625rem;
  .buttons-container {
    display: inline-block;
    margin: 0 .625rem;
    height: 100%;
    flex-wrap: wrap;
  }
}

.custom-single-buttons-container,
.custom-group-buttons-container {
  height: 100%;
  margin: 0 0.625rem 0.625rem 0.625rem;
  .circle-group-buttons-start {
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .circle-group-buttons-end {
    border-top-right-radius: 30px !important;
    border-bottom-right-radius: 30px !important;
  }
  .btn,
  .btn-group,
  .btn-group-vertical {
    input {
      display: none;
    }
    display: flex;
    align-items: center;
    padding: 0 1.5rem 0 1.5rem;
    height: 100%;
    font-size: 0.95rem;
    min-width: 4.5rem;
    justify-content: center;
    svg {
      height: 17px;
      width: 17px;
      transform: rotate(180deg);
      margin-right: 5px;
      fill: $buttonBackgroundColor;
      font-size: .95rem;
    }
  }
  .btn-group,
  .btn-group-vertical {
    padding: 0;
    .btn {
      margin-bottom: 0;
    }
  }
  .circle-button {
    .btn {
      border-radius: 30px;
      height: 100%;
    }
  }
  .big,
  .big-outline,
  .big-inline {
    width: 100%;
    .btn {
      margin-top: 0.625rem;
      font-family: "Montserrat", sans-serif;
      display: inline-block;
      font-weight: 500;
      outline: none;
      font-size: 1.25rem;
      width: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .primary {
    height: 100%;
    .btn,
    .btn-group,
    .btn-group-toggle,
    .btn-big {
      border-color: $buttonBackgroundColor;
      background-color: $buttonBackgroundColor;
      font-weight: 500;
      svg {
        fill: $textColor;
      }
    }
    &:hover {
      background-color: $buttonBackgroundColorHover;
      border-color: $buttonBackgroundColorHover;
    }
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: $activeButtonColor;
      border-color: $activeButtonColor;
      box-shadow: none;
      &:hover {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
      }
      svg {
        fill: $textColor;
      }
      &:focus {
        background-color: $activeButtonColorHover;
        box-shadow: none;
      }
      &:disabled {
        border-color: $secondaryButtonTextColor;
        background-color: $secondaryButtonTextColor;
        font-weight: 500;
        color: $textColor;
        cursor: default;
      }
    }
  }
  .outline-primary {
    height: 100%;
    .btn,
    .btn-group,
    .btn-group-toggle {
      border-color: $buttonBackgroundColor;
      background-color: $buttonOutlineBackgroundColor;
      color: $buttonBackgroundColor;
      font-weight: 500;
      height: 100%;
    }
    .btn-outline-primary:not(:disabled):not(.disabled).active,
    .btn-outline-primary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-primary.dropdown-toggle,
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: $activeButtonColor;
      border-color: $activeButtonColor;
      box-shadow: none;
      svg {
        fill: $textColor;
      }
      &:hover {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
      }
      &:focus {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
        box-shadow: none;
        color: $textColor;
        svg {
          fill: $textColor;
        }
      }
    }
    .btn:hover {
      background-color: $rightMenuSelectedBackgroundColor;
      border-color: $rightMenuSelectedBackgroundColor;
    }
    .btn:focus {
      background-color: $activeButtonColorHover;
      border-color: $activeButtonColorHover;
      color: $textColor;
      svg {
        fill: $textColor;
      }
    }
  }
  .outline-secondary {
    height: 100%;
    .btn,
    .btn-group {
      border-color: $secondaryButtonTextColor;
      color: $secondaryButtonTextColor;
      background-color: $buttonOutlineBackgroundColor;
      font-weight: 500;
      height: 100%;
      svg {
        fill: $secondaryButtonTextColor;
      }
      &:hover {
        svg {
          fill: $secondaryButtonHoverTextColor;
        }
        color: $secondaryButtonHoverTextColor;
        border-color: $secondaryButtonHoverBorderColor;
      }
      &:focus {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
        color: $textColor;
        box-shadow: none;
        svg {
          fill: $textColor;
        }
      }
    }
    .btn-outline-secondary:not(:disabled):not(.led).active,
    .btn-outline-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-outline-secondary.dropdown-toggle,
    .btn-primary:not(:disabled):not(.disabled).active,
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
      background-color: $buttonBackgroundColor;
      border-color: $buttonBackgroundColor;
      color: $textColor;
      box-shadow: none;
      svg {
        fill: $textColor;
      }
      &:hover {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
        svg {
          fill: $textColor;
        }
      }
      &:focus {
        background-color: $activeButtonColorHover;
        border-color: $activeButtonColorHover;
        color: $textColor;
        box-shadow: none;
        svg {
          fill: $textColor;
        }
      }
      &:disabled {
        border-color: $secondaryButtonTextColor;
        background-color: $secondaryButtonTextColor;
        font-weight: 500;
        color: $textColor;
        cursor: default;
        opacity: 0.5;
      }
    }
  }
  .secondary {
    height: 100%;
    .btn,
    .btn-group {
      border-color: $inactiveButtonBackgroundColor;
      background-color: $inactiveButtonBackgroundColor;
      font-weight: 500;
      color: $textColor;
      height: 100%;
      &:hover {
        background-color: $buttonBackgroundColorHover;
        border-color: $buttonBackgroundColorHover;
      }
    }
    .btn-secondary:not(:disabled):not(.disabled).active,
    .btn-secondary:not(:disabled):not(.disabled):active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: $activeButtonColorHover;
      border-color: $activeButtonColorHover;
      box-shadow: none;
      svg {
        fill: $textColor;
      }
      &:focus {
        background-color: $activeButtonColorHover;
        box-shadow: none;
      }
    }
  }
  .big-inline {
    .btn {
      background-color: none;
      box-shadow: unset !important;
      background-color: unset;
      border: unset;
      border-radius: 30px;
      text-decoration: underline;
      color: $textColor;
      &:focus {
        box-shadow: 0 0 0 0.1rem $buttonFocusShadow !important;
        border-radius: 30px;
      }
    }
  }
  .big-outline {
    .btn {
      font-size: 1.25rem;
      background-color: $buttonOutlineBackgroundColor;
      color: $codeColor;
      padding: 0.3rem 2rem;
      max-height: 32px;
      &:focus {
        background-color: $activeButtonColorHover;
        color: $textColor;
        box-shadow: none;
      }
    }
  }
  .btn-outline-secondary {
    &:disabled {
      opacity: 1;
      background-color: $outlineSecondaryDisabledColor;
      border-color: $outlineSecondaryDisabledColor;
      color: $textColor;
      cursor: default;
      &:hover {
        color: $textColor;
        background-color: $outlineSecondaryDisabledColor;
        border-color: $outlineSecondaryDisabledColor;
        cursor: default;
      }
    }
  }
}
.circle-group-buttons {
  background-color: unset;
  .btn,
  .btn-group,
  .btn-group-toggle {
    border-radius: 30px;
    height: 100%;
    box-shadow: $buttonBoxShadow;
  }
}
