.a4-content {
  width: 100%;
  height: 100%;
}
.a4-additional-scanned-images-container {
  width: 100%;
  height: 41rem;
  padding: 1rem;
  .a4-additional-scanned-images {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    .wrapper-transform-component {
      width: 100%;
      height: 20rem;
    }
  }
}