.tooltip.show {
  opacity: 1 !important;
}
  .tooltip-inner {
    max-width: 100%;
    padding: .25rem .5rem;
    color: $toolTipInnerColor;
    text-align: start;
    background-color: $toolTipBackgroundColor;
    border-radius: .25rem;
    opacity: 1;
  }

  .bs-tooltip-left {
    .arrow:before {
      border-left-color: $toolTipBackgroundColor;
      opacity: 1;
      border-radius: .25rem;
    }
  }

  .bs-tooltip-top {
    .arrow:before {
      border-top-color: $toolTipBackgroundColor;
      opacity: 1;
      border-radius: .25rem;
    }
  }

  .bs-tooltip-bottom {
    .arrow:before {
      border-bottom-color: $toolTipBackgroundColor;
      opacity: 1;
      border-radius: .25rem;
    }
  }

  .bs-tooltip-rigth {
    .arrow:before {
      border-right-color: $toolTipBackgroundColor;
      opacity: 1;
      border-radius: .25rem;
    }
  }
